<template>
    <div>
        <SubHeader :title="'Details'"/>
        <div class="content_wrap">
            <div class="bg-yellow-1 rounded-top ">
                <div class="p-3 flex-between-start">
                    <div class="d-flex flex-column">
                        <h6 class="mb-2 lh-base fs-px-14 fw-bold">How is a Winning Number drawn at OCB?<br>(Remainder Calculation)</h6>
                        <p class="fs-px-12 fw-400">A / B = Quotient R Remainder +1<br> example) 17/5 = 3R2, 2+1=3 "LUCKY NUMBER is 3"</p>
                    </div>
                    <img src="@/assets/img/layout_icon/sound.svg" alt="sound" width="36">
                </div>
            </div>
            <div class="bg-yellow-4 rounded-bottom">
                <div class="p-3">
                    <div class="flex-start-start">
                        <div class="bg-yellow-2 rounded w-px-26 h-px-26 lh-px-26 flex-shrink-0 text-center me-2"><b class="fs-px-16">A</b></div>
                        <p class="fs-px-12 lh-sm">A) Sum of the latest 50 ticket purchases' timestamps within the platform, regardless of which deal.</p>
                    </div>
                    <hr>
                    <div class="flex-start-center">
                        <div class="bg-yellow-2 rounded w-px-26 h-px-26 lh-px-26 flex-shrink-0 text-center me-2"><b class="fs-px-16">B</b></div>
                        <p class="fs-px-12 lh-sm">B) Total number tickets issued</p>
                    </div>
                </div>
            </div>
            <div class="py-3">
                <div class="flex-between-center fs-px-17">
                    <span class="text-burgundy-4">A</span>
                    <div @click="open=!open" class="flex-between-center">
                        <span class="text-white">{{win_number}}</span>
                        <span class="ms-3 d-inline-block lh-1" :class="{'rotate-180 mt-1':open}">
                            <img src="@/assets/img/layout_icon/arrow_down_y.svg" alt="More" width="16">
                        </span>
                    </div>
                </div>
                <div v-if="open" class="py-3 text-white">
                    <div class="flex-between-center text-burgundy-4 fs-px-13">
                        <span>Time of Purchase</span>
                        <span>User</span>
                    </div>
                    <div>
                        <ul class="text-white fs-px-13">
                            <li v-for="(item,index) in list" :key="index" class="py-2 border-bottom border-burgundy-4 flex-between-center">
                                <div class="flex-shrink-0 me-3">
                                    <span>{{$formatDate_full(item.create_time)}}</span>
                                    <small class="mx-1 fs-px-11">→</small>
                                    <span class="text-yellow-1">{{item.h}}{{item.m}}{{item.s}}{{item.f}}</span>
                                </div>
                                <div class="text-truncate">
                                    <span>{{$hide_last_str(item.nick_name)}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex-between-center pt-3 fs-px-17">
                    <span class="text-burgundy-4 ">B</span>
                    <span class="text-white">{{Number(total_number).toLocaleString()}}</span>
                </div>
                <hr class="border-yellow-1">
                <div class="flex-between-center fs-px-17">
                    <span class="text-burgundy-4">Calculation Result</span>
                    <span class="text-white">{{Number(result).toLocaleString()}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

const CryptoJS = require("crypto-js");

import SubHeader from '@/components/common/SubHeader.vue'
export default {
    components:{
        SubHeader
    },
    data(){
        return{
            code : this.$route.params.code,
            open:false,
            list: [],
            win_number : '',
            total_number : '',
            result : '',
        }
    },
    mounted(){
        this.GetWinnerInfo();
    },
    methods:{
        // $formatDate() common.js로 옮김. $formatDate_full(dateTimeStr) 형식으로 쓰면 됩니다.
        
        GetWinnerInfo(){
            const code = this.code;
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/deal/GetWinnerInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.list = body.list;
                            this.win_number = body.a;
                            this.total_number = body.count
                            this.result = body.data;
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                    location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
    }
}
</script>